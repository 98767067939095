import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { Observable, of } from 'rxjs';
import { TimerService } from '../timer/timer.service';

type NextModule = {
  name: string;
  nextPages: string[];
  load: Function;
  isLoad: boolean;
};

@Injectable()
export class PreloadNextModules implements PreloadingStrategy {
  private pages: { [page: string]: NextModule } = {};
  constructor(private timerService: TimerService) {}

  preload(route: Route, load: Function): Observable<any> {
    if (route?.data?.name && !this.pages[route.data.name]) {
      this.pages[route.data.name] = {
        name: route.data.name,
        load,
        nextPages: route.data?.nextPages || [],
        isLoad: false,
      };
    }
    if (route?.data?.preload) {
      return load();
    }
    return of(null);
  }

  preloadNextPages(currentPage: string) {
    this.timerService.setTimeout(() => this.preloadCurrentPage(currentPage), 500);
  }

  private preloadCurrentPage(currentPage: string) {
    if (currentPage === ROUTE_URLS.employee && !this.pages[currentPage]) {
      this.pages[currentPage] = {
        name: ROUTE_URLS.employee,
        nextPages: [ROUTE_URLS.collection, ROUTE_URLS.invoices],
        load: () => {},
        isLoad: false,
      };
    }

    const route = this.pages[currentPage];
    if (route?.nextPages?.length) {
      const unloadPages = route.nextPages.map((name) => this.pages[name]).filter((route) => route && !route.isLoad);
      if (!unloadPages.length) {
        return;
      }
      unloadPages.forEach((page) => this.loadPage(page));
      this.pages[currentPage].isLoad = true;
    }
  }

  private loadPage(route: NextModule) {
    if (route && !route?.isLoad) {
      route.load();
      route.isLoad = true;
    }
  }
}

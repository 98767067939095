export enum ProtocolVersion {
  v3_0 = '3.0',
}

export enum MessageClass {
  Device = 'Device',
  Service = 'Service',
}

export enum MessageCategory {
  Payment = 'Payment',
  Print = 'Print',
  Admin = 'Admin',
  TransactionStatus = 'TransactionStatus',
}

export enum MessageType {
  Request = 'Request',
}

export enum DocumentQualifier {
  CashierReceipt = 'CashierReceipt',
  CustomerReceipt = 'CustomerReceipt',
}

export enum RequestName {
  PaymentRequest = 'PaymentRequest',
  PrintRequest = 'PrintRequest',
  AdminRequest = 'AdminRequest',
  TransactionStatusRequest = 'TransactionStatusRequest',
}

export enum ErrorCondition {
  InProgress = 'InProgress',
  NotFound = 'NotFound',
  Busy = 'Busy',
  Refusal = 'Refusal',
}

export enum ResponseResult {
  Failure = 'Failure',
  Success = 'Success',
}

<ion-menu side="start" swipeGesture="false" contentId="main">
  <ion-toolbar (click)="hideSideMenu()">
    <div class="flex-all-center">
      <img src="assets/images/Paymash-logo-blue.svg" width="150" />
    </div>
  </ion-toolbar>
  <ion-content id="content">
    <ion-list class="ion-no-padding">
      <current-user class="tablet-hide"></current-user>
      <ion-item button *ngFor="let page of pages" (click)="goToPage(page)" detail="false" [class.page-selected]="page.isSelected">
        <i class="menu-icon menu-icon-{{ page.icon }}"></i>
        <span>{{ page.title | translate }}</span>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

import { AdyenTerminalInfo } from '../../classes';
import { AdyenApiHelper } from './adyen-api-helper.service';
import { Injectable } from '@angular/core';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { ApiTerminals } from '../../adyen-types';
import { LoadingService } from '@pos-common/services/system/loading.service';
import { LogService } from '@pos-common/services/system/logger';

@Injectable()
export class AdyenTerminalApi {
  private readonly baseUrl = SERVER_CONFIG.ADYEN_MANAGEMENT_URL;
  private readonly logger = this.logService.createLogger('AdyenTerminalApi');

  constructor(
    private readonly apiHelper: AdyenApiHelper,
    private readonly loadingService: LoadingService,
    private logService: LogService
  ) {}

  getTerminals(options: { merchantId: string }): Promise<AdyenTerminalInfo[]> {
    const url = `${this.baseUrl}terminals?merchantIds=${options.merchantId}`;
    this.loadingService.showLoadingItem();
    return this.apiHelper
      .get(url, { errorHandler: this.processError.bind(this) })
      .then((terminals: ApiTerminals) => {
        terminals.data = terminals?.data || [];
        return terminals.data.map(({ deviceModel, serialNumber }) => new AdyenTerminalInfo(deviceModel, serialNumber));
      })
      .finally(() => this.loadingService.hideLoadingItem());
  }

  private processError(error: unknown) {
    const apiErrorDetails = this.decodeApiErrorDetails(error);
    if (!apiErrorDetails) {
      this.logger.error(error, 'Unknown api error');
      return new Error('Unknown api error');
    }
    return new Error(apiErrorDetails.title);
  }

  private decodeApiErrorDetails(error: any) {
    if (typeof error !== 'object') return undefined;
    if (!error.error || typeof error.error !== 'string') return undefined;

    const errorDetailsString = error.error;

    try {
      const errorDetails: { title: string } = JSON.parse(errorDetailsString);
      return errorDetails;
    } catch (e) {
      return undefined;
    }
  }
}

import { Injectable } from '@angular/core';
import { PAYMASH_PROFILE } from '@profile';
import { ElasticLogger as ElasticLoggerBase } from '@paymash/logger-capacitor';
import { HttpClient } from '@spryrocks/capacitor-http-plugin'

@Injectable()
export class ElasticLogger extends ElasticLoggerBase {
  constructor() {
    super(HttpClient, {
      serverUrl: PAYMASH_PROFILE.LOGGER.url,
      system: `pm.pos.${PAYMASH_PROFILE.APP_ENV}`,
      username: PAYMASH_PROFILE.LOGGER.username,
      password: PAYMASH_PROFILE.LOGGER.password,
      debug: true,
    });
  }
}

// Vendors
import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { SERVER_CONFIG } from '../../constants/server.const';
import { SecurityService } from './security.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { DbDaoService } from '../db/db-dao.service';
import { NetworkService } from './network.service';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from './logger/log.service';
import { PlatformService } from './platform/platform.service';
import { PAYMASH_PROFILE } from '@profile';

@Injectable()
export class AdminPageService {
  private _isFirstLaunch: boolean = true;
  private logoutWasMade: boolean = false;
  private emploeeUiId: any = null;
  private readonly logger = this.logService.createLogger('AdminPageService');

  constructor(
    private securityService: SecurityService,
    private dbDaoService: DbDaoService,
    private networkService: NetworkService,
    private alertService: AlertService,
    private inAppBrowser: InAppBrowser,
    private translateService: TranslateService,
    private platformService: PlatformService,
    private logService: LogService
  ) {}

  public setLogout(emploeeUiId) {
    if (this.emploeeUiId && this.emploeeUiId !== emploeeUiId) {
      this.logoutWasMade = false;
    }
    this.emploeeUiId = emploeeUiId;
  }

  public open(url: string, options?: InAppBrowserOptions) {
    if (this.networkService.getConnectionStatus()) {
      this.offlineAlertShow();
      return;
    }
    const adminPage = this.inAppBrowser.create(url, '_blank', this.getOptions(options));

    if (this.platformService.isWeb) {
      return;
    }

    let isFirstLoad = true;
    adminPage.on('loadstart').subscribe((event) => {
      const { url } = event;
      if (this.hasUrlParam(url, 'logout') || this.hasUrlParam(url, 'close')) {
        this.closePage(adminPage);
      }

      if (!isFirstLoad) {
        return;
      }
      isFirstLoad = false;
      this.setAdminSettings(adminPage).catch((err) => this.logger.error(err, 'open:setAdminSettings'));
    });

    adminPage.on('loadstop').subscribe(async (event) => {
      let { url } = event;
      if (!this.isFullUrl(url)) {
        url = await this.getUrlFromAdminPage(adminPage);
      }
      if (this.hasUrlParam(url, 'logout') || this.hasUrlParam(url, 'close')) {
        this.closePage(adminPage);
        return;
      }

      const closeButton = this.getCloseButton();
      adminPage
        .executeScript({
          code:
            '(function() {' +
            ((this._isFirstLaunch && this.hasUrlParam(url, 'login')) ||
              '  sessionStorage.setItem("posEmail", "' + this.getActiveEmployeeEmail() + '");') +
            closeButton.code +
            //add close handler when create new product
            ((this._isFirstLaunch && url === 'app#/inventory/0/products/new') ||
              'document.querySelectorAll(".cancel").forEach(function(elem){elem.onclick = function() { ' +
                '  window.location.href = "/logout"; ' +
                '}});') +
            '})()',
        })
        .catch((err) => this.logger.error(err, 'open:adminPage:insertCSS'));

      adminPage
        .insertCSS({ code: closeButton.style + this.getInAppStyles() })
        .catch((err) => this.logger.error(err, 'open:adminPage:insertCSS'));
    });

    if (this._isFirstLaunch) {
      this._isFirstLaunch = false;
    }
  }

  public openInvoice(invoiceUuid: string) {
    const url = `${SERVER_CONFIG.SERVER_URL}/app#/proposals/${invoiceUuid}`;
    this.open(url);
  }

  private getActiveEmployeeEmail() {
    const activeEmployee = this.securityService.getActiveEmployee();
    return activeEmployee?.email || '';
  }

  private getLoggedUserDataAccessToken() {
    const user = this.securityService.getLoggedUserData();
    return user?.accessToken || '';
  }

  public openSecured(url: string) {
    this.checkAccessStatus()
      .then((isAccessAvailable) => {
        if (isAccessAvailable) {
          this.securityService
            .refreshToken('', '', null)
            .then(() => {
              this.open(SERVER_CONFIG.SERVER_URL + '/login?jwt=' + this.getLoggedUserDataAccessToken() + '&redirect=/' + url);
            })
            .catch((err) => this.logger.error(err, 'openSecured:checkAccessStatus:then:refreshToken'));
        } else {
          this.logoutWasMade
            ? this.openWithLogout(SERVER_CONFIG.SERVER_URL + '/app#/dashboard')
            : this.openWithLogout(SERVER_CONFIG.SERVER_URL + '/app#/login?');
        }
      })
      .catch((err) => this.logger.error(err, 'openSecured:checkAccessStatus'));
  }

  public openWithLogout(url: string) {
    const adminPage = this.inAppBrowser.create(url, '_blank', this.getOptions());
    if (this.platformService.isWeb) {
      return;
    }

    let isFirstLoad = true;
    adminPage.on('loadstart').subscribe((event) => {
      const { url } = event;
      if (this.hasUrlParam(url, 'close')) {
        this.closePage(adminPage);
      }

      if (this.logoutWasMade && this.hasUrlParam(url, 'login') && url.indexOf('login?') < 0) {
        adminPage
          .executeScript({
            code:
              '(function() {' +
              'window.location.href = "' +
              SERVER_CONFIG.SERVER_URL +
              '/login?email=' +
              this.getActiveEmployeeEmail() +
              '"' +
              '})()',
          })
          .catch((err) => this.logger.error(err, 'openWithLogout: adminPage:executeScript'));
      } else {
        if (!this.logoutWasMade) {
          setTimeout(() => (this.logoutWasMade = true), 1000);
          adminPage
            .executeScript({
              code: `
                            (function() {
                                angular.element(document.querySelector('body')).injector().get('AuthService').logout();
                            })();
                        `,
            })
            .catch((err) => this.logger.error(err, 'openWithLogout: adminPage:executeScript'));
        }
      }
      if (!isFirstLoad) {
        return;
      }
      isFirstLoad = false;
      this.setAdminSettings(adminPage).catch((err) => this.logger.error(err, 'openWithLogout:setAdminSettings'));
    });

    adminPage.on('loadstop').subscribe(async (event) => {
      let { url } = event;
      if (!this.isFullUrl(url)) {
        url = await this.getUrlFromAdminPage(adminPage);
      }
      if (this.hasUrlParam(url, 'close')) {
        this.closePage(adminPage);
        return;
      }

      const closeButton = this.getCloseButton();
      adminPage
        .executeScript({
          code:
            '(function() {' + '  sessionStorage.setItem("posEmail", "' + this.getActiveEmployeeEmail() + '");' + closeButton.code + '})()',
        })
        .catch((err) => this.logger.error(err, 'openWithLogout: adminPage:executeScript'));

      adminPage
        .insertCSS({ code: closeButton.style + this.getInAppStyles() })
        .catch((err) => this.logger.error(err, 'openWithLogout: adminPage:insertCSS'));
    });
    if (this._isFirstLaunch) {
      this._isFirstLaunch = false;
    }
  }

  //DO NOT Understood for what we need this method?
  public logout() {
    if (this.platformService.isWeb) {
      return;
    }

    const adminPage = this.inAppBrowser.create(SERVER_CONFIG.SERVER_URL + '/logout', '_blank', 'hidden=yes');
    adminPage.on('loadstop').subscribe(() => {
      setTimeout(() => {
        adminPage.close();
      }, 300);
    });
  }

  public async offlineAlertShow() {
    const alert = await this.alertService.create({
      header: this.translateService.instant('global_status_offline_title'),
      subHeader: this.translateService.instant('global_status_offline_message'),
      buttons: ['OK'],
    });
    alert.present().catch((err) => this.logger.error(err, 'offlineAlertShow: alert:present'));
  }

  public checkAccessStatus() {
    return this.dbDaoService
      .getAllData(UPDATES_TYPES.Employee.type, { deleted: false })
      .then((employees) => {
        return employees['data'].every((employee) => employee.hasAdminPermission);
      })
      .catch((err) => this.logger.error(err, 'checkAccessStatus:getDataByParams'));
  }

  private getCloseButton(): { code: string; style: string } {
    return {
      code: `
                        var body = document.querySelector("body");
                        var link = document.createElement("a");
                        link.classList.add("done-button");
                        function makeCloseUrl() {
                            var currentPath = location.href;
                            var prefix = currentPath.indexOf('?') !== -1 ? '&' : '?';
                            return currentPath + prefix + 'close=true';
                        }
                        link.href = makeCloseUrl();
                        body.appendChild(link);
                  `,
      style: `
                        .done-button {
                           position: fixed;
                           left: 0px;
                           top: 0px;
                           margin: 12px;
                           width: 48px;
                           height: 48px;
                           z-index: 9999;
                         }
                        .done-button:before, .done-button:after {
                          position: absolute;
                          left: 12px;
                          content: " ";
                          height: 30px;
                          width: 1px;
                          background-color:#007aff;
                        }
                        .done-button:before {
                          transform: rotate(45deg);
                        }
                        .done-button:after {
                          transform: rotate(-45deg);
                        }
                        .pull-left>h3.title {
                          margin-left: 20px;
                        }
                        @media (max-width: 991px) and (orientation: portrait) {
                            .done-button {
                              display: none;
                            }
                        }
                        @media (max-width: 775px) {
                            .container.sign-up-container {
                             padding-top: 30px;
                            }
                        }
                    `,
    };
  }

  private getInAppStyles() {
    return `.inappbrowser .login-web .email {
      display: block !important;
      pointer-events: none;
      opacity: 0.7;
    }`;
  }

  private hasUrlParam(url: string, param: string) {
    return url.indexOf(param) > -1;
  }

  private closePage(page: InAppBrowserObject) {
    page.close();
    this._isFirstLaunch = true;
  }

  private isFullUrl(url: string) {
    return url.indexOf('#') > -1;
  }

  private async getUrlFromAdminPage(page: InAppBrowserObject) {
    let url = '';
    try {
      const values = await page.executeScript({ code: 'location.href' });
      const [href] = values;
      if (href) {
        url = href;
      }
    } catch (err) {
      this.logger.error(err, 'openWithLogout:loadStop: adminPage:executeScript');
    }
    return url;
  }

  private getOptions(options?: InAppBrowserOptions) {
    const browserOptions: InAppBrowserOptions = {
      location: 'no',
      toolbar: 'yes',
      transitionstyle: 'crossdissolve',
      closebuttoncaption: this.translateService.instant('common_done'),
      ...options,
    };

    return Object.keys(browserOptions)
      .map((key) => `${key}=${browserOptions[key]}`)
      .join(',');
  }

  private setAdminSettings(page: InAppBrowserObject) {
    const { hostname } = new URL(PAYMASH_PROFILE.SERVER_URL);
    const { currentLang } = this.translateService;
    return page.executeScript({
      code: `
          (function() {
              document.cookie = 'lang=${currentLang};path=/;domain=${hostname}';
              localStorage.setItem('isPosBrowser', 'true');
              window.location.reload();
          })();
        `,
    });
  }
}

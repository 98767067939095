import { Injectable } from '@angular/core';
import { ImageLoaderService } from '@spryrocks/ionic-image-loader-v5';

interface QueueItem {
  imageUrl: string;
  resolve: Function;
  reject: Function;
}

@Injectable({ providedIn: 'root' })
export class ImageLoaderLibService extends ImageLoaderService {
  /**
   * Gets the filesystem path of an image.
   * This will return the remote path if anything goes wrong or if the cache service isn't ready yet.
   * @param imageUrl The remote URL of the image
   * @returns Returns a promise that will always resolve with an image URL
   */
  async getImagePath(imageUrl: string): Promise<string> {
    if (typeof imageUrl !== 'string' || imageUrl.length <= 0) {
      throw new Error('The image url provided was empty or invalid.');
    }

    await this.ready();

    if (!this['isCacheReady']) {
      this['throwWarning']('The cache system is not running. Images will be loaded by your browser instead.');
      return imageUrl;
    }

    if (this['isImageUrlRelative'](imageUrl)) {
      return imageUrl;
    }

    try {
      return await this['getCachedImagePath'](imageUrl);
    } catch (err) {
      // image doesn't exist in cache, lets fetch it and save it
      return this.addItemToQueueOverride(imageUrl);
    }
  }

  /**
   * Add an item to the queue
   * @param imageUrl
   * @param resolve
   * @param reject
   */
  private addItemToQueueOverride(imageUrl: string, resolve?, reject?): void | Promise<any> {
    let p: void | Promise<any>;

    if (!resolve && !reject) {
      p = new Promise<any>((res, rej) => {
        resolve = res;
        reject = rej;
      });
    } else {
      resolve = resolve || (() => {});
      reject = reject || (() => {});
    }

    this['queue'].push({
      imageUrl,
      resolve,
      reject,
    });

    this.processQueueOverride();

    return p;
  }
  /**
   * Processes one item from the queue
   */
  private async processQueueOverride() {
    // make sure we can process items first
    if (!this['canProcess']) {
      return;
    }

    // increase the processing number
    this['processing']++;

    // take the first item from queue
    const currentItem: QueueItem = this['queue'].splice(0, 1)[0];

    // function to call when done processing this item
    // this will reduce the processing number
    // then will execute this function again to process any remaining items
    const done = () => {
      this['processing']--;
      this.processQueueOverride();

      // only delete if it's the last/unique occurrence in the queue
      if (this['currentlyProcessing'][currentItem.imageUrl] !== undefined && !this['currentlyInQueue'](currentItem.imageUrl)) {
        delete this['currentlyProcessing'][currentItem.imageUrl];
      }
    };

    const error = (e) => {
      // currentItem.reject(e);
      currentItem.reject();
      this['throwError'](e);
      done();
    };

    if (this['currentlyProcessing'][currentItem.imageUrl] !== undefined) {
      try {
        // Prevented same Image from loading at the same time
        await this['currentlyProcessing'][currentItem.imageUrl];
        const localUrl = await this['getCachedImagePath'](currentItem.imageUrl);
        currentItem.resolve(localUrl);
        done();
      } catch (err) {
        error(err);
      }
      return;
    }

    this['currentlyProcessing'][currentItem.imageUrl] = (async () => {
      // process more items concurrently if we can
      if (this['canProcess']) {
        this.processQueueOverride();
      }

      const localDir = await this.getFileCacheDirectory().getDirectory(this['config'].cacheDirectoryName);
      const fileName = this['createFileName'](currentItem.imageUrl);

      try {
        const data = await this['http']
          .get(currentItem.imageUrl, {
            responseType: 'arraybuffer',
            headers: this['config'].httpHeaders,
          })
          .toPromise();

        const file = await localDir.getFile(fileName);
        await file.writeBytes(new Uint8Array(data));

        if (this['isCacheSpaceExceeded']) {
          this['maintainCacheSize']();
        }

        await this['addFileToIndex'](file);
        const localUrl = await this['getCachedImagePath'](currentItem.imageUrl);
        currentItem.resolve(localUrl);
        done();
        this['maintainCacheSize']();
      } catch (err) {
        error(err);
        // POS: Disabled this line because it throws the error to PaymashErrorHandler
        // throw err;
      }
    })();
  }
}

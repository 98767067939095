import { filter, map, pairwise, timeout } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { APP_INFO } from '../../constants/app-info.const';
import { DbDaoService } from '../db/db-dao.service';
import { LoadingService } from './loading.service';
import { LocalStorage } from '../utils/localstorage.utils';
import { SecuredResponse } from '../../classes/secured-response.class';
import { UuidService } from '../utils/uuid.utils';
import { Employee } from '../../classes/employee.class';
import { Store } from '../../classes/store.class';
import { SERVER_CONFIG } from '../../constants/server.const';
import { License } from '../../classes/license.class';
import { TranslateService } from '@ngx-translate/core';
import { RequestsWbService } from './requests-wb.service';
import { Device } from '@ionic-native/device/ngx';
import { GoogleAnalyticsService } from './google-analitycs.service';
import { Company } from '../../classes/company.class';
import { Image, IMAGE_SIZES } from '../../classes/image.class';
import { BehaviorSubject, Observable, TimeoutError } from 'rxjs';
import { AlertService } from './alert.service';
import { ConvertDataForRequest } from '../../decorators/convert-data.decor';
import { CoreFields, LogService, stringifySafety } from './logger';
import { PosSettingsService } from './pos-settings.service';
import { PAYMASH_PROFILE } from '@profile';
import { RequestHeaderTypes } from '../../constants/request-headers.enum';
import { StorageKeys } from '../../constants/storage.const';
import { FileReaderUtils } from '../utils/file-reader/file-reader.utils';
import { SERVER_RESPONSE_ERRORS } from '@pos-common/constants/server-response-errors.const';
import { ISecureRequestOptions, ServerError } from '../../interfaces';
import { CompanyProperties } from '@pos-common/constants';
import { FingerprintPlugin } from '@paymash/capacitor-fingerprint-plugin';
import { ErrorLevel } from '@spryrocks/logger';

export interface LoggedUserDataInterface {
  accessToken: string;
  refreshToken: string;
  pos: { channelId: number };
  company: Company;
  user: Employee;
}

@Injectable()
export class SecurityService {
  public loggedUserData: LoggedUserDataInterface = null;
  public loggedCompanyData: BehaviorSubject<Company> = new BehaviorSubject(null);
  public deviceIdentifier: string;
  public deviceFingerprint: string;
  public activeEmployee: Employee = null;
  public activeStore: Store = null;
  public badRefreshToken = new EventEmitter();
  // TODO ADJUST LOGIC OF REPEATED SUCCESS PAGE LOAD
  public isSuccessPageActive: boolean = false;
  public activeEmployeeUpdateEvent: EventEmitter<any> = new EventEmitter();
  public activeStoreUpdateEvent: EventEmitter<any> = new EventEmitter();
  public logoutEvent: EventEmitter<any> = new EventEmitter();
  private errorAlert: any;
  private companyLogo: string;
  private storeList$ = new BehaviorSubject<Store[]>([]);
  public resyncEvent: EventEmitter<any> = new EventEmitter();
  private readonly logger = this.logService.createLogger('SecurityService');

  constructor(
    public http: HttpClient,
    private LocalStorage: LocalStorage,
    private LoadingService: LoadingService,
    private UuidService: UuidService,
    private DbDaoService: DbDaoService,
    private TranslateService: TranslateService,
    private AlertService: AlertService,
    public RequestsWbService: RequestsWbService,
    private Device: Device,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private posSettingsService: PosSettingsService,
    private logService: LogService
  ) {}

  public setLoggedUserData(data: LoggedUserDataInterface) {
    if (data !== null) {
      this.posSettingsService.setCompanyUuid(data.company.uuid);
      this.LocalStorage.setObject(StorageKeys.loggedUserData, data);
      try {
        this.logService.setSentryTag(CoreFields.CompanyUuid, data.company.uuid);
        this.logService.setSentryTag(CoreFields.CompanyId, data.company.id.toString());
        this.logService.setField(CoreFields.CompanyUuid, data.company.uuid);
        this.logService.setField(CoreFields.CompanyId, data.company.id.toString());
      } catch (err) {
        this.logger.error(err, `setLoggedUserData:logService.companyUuid`);
      }
    } else {
      this.posSettingsService.setCompanyUuid(null);
      this.LocalStorage.remove(StorageKeys.loggedUserData);
      this.logService.setSentryTag(CoreFields.CompanyUuid, undefined);
      this.logService.setSentryTag(CoreFields.CompanyId, undefined);
      this.logService.setField(CoreFields.CompanyUuid, null);
      this.logService.setField(CoreFields.CompanyId, null);
    }
    this.loggedUserData = data;
  }

  public getCompanyLogoBase64(companyData: Company) {
    return new Promise((resolve, reject) => {
      if (companyData.image) {
        const image = new Image(companyData.image);
        const imageURL: string = image.getImageUrlBySize(IMAGE_SIZES.NORMAL);
        this.http
          .get(imageURL, { responseType: 'blob' })
          .toPromise()
          .then((data) => {
            const blob = data;
            const reader = FileReaderUtils.getFileReader();
            reader.onload = function (event) {
              const base64String = event.target['result'] as string;
              resolve(base64String);
            };
            reader.readAsDataURL(blob);
          })
          .catch((err) => {
            this.logger.error(err, 'getCompanyLogoBase64');
            reject(err);
          });
      } else {
        reject();
      }
    });
  }

  private removeCompanyLogo() {
    this.LocalStorage.remove(StorageKeys.companyLogo);
    this.companyLogo = null;
  }

  public saveCompanyLogo(data) {
    this.LocalStorage.set(StorageKeys.companyLogo, data);
    this.companyLogo = data;
  }

  public getCompanyLogo(): string {
    if (!this.companyLogo) {
      this.companyLogo = this.LocalStorage.get(StorageKeys.companyLogo);
    }
    return this.companyLogo && this.companyLogo.indexOf('base64') > -1 && this.companyLogo.split('base64,')[1];
  }

  public getLoggedUserData() {
    return this.loggedUserData;
  }

  public getDeviceIdentifier() {
    if (this.deviceIdentifier) return this.deviceIdentifier;

    let deviceIdentifier = this.LocalStorage.get(StorageKeys.deviceIdentifier);
    if (deviceIdentifier) {
      this.deviceIdentifier = deviceIdentifier;
    } else {
      this.deviceIdentifier = this.UuidService.generate();
      this.LocalStorage.set(StorageKeys.deviceIdentifier, this.deviceIdentifier);
    }
    return this.deviceIdentifier;
  }

  public async setDeviceFingerprint() {
    if (this.deviceFingerprint) {
      return;
    }

    let deviceFingerprint = this.LocalStorage.get(StorageKeys.deviceFingerprint);
    if (!deviceFingerprint) {
      deviceFingerprint = await this.getFingerprint();
      this.LocalStorage.set(StorageKeys.deviceFingerprint, deviceFingerprint);
    }
    this.deviceFingerprint = deviceFingerprint;
  }

  public getDeviceFingerprint() {
    return this.deviceFingerprint;
  }

  getNextInvoiceNumber(): number {
    const nextInvoiceNumber = this.LocalStorage.get('nextInvoiceNumber');
    return nextInvoiceNumber ? parseInt(nextInvoiceNumber) : 1;
  }

  increaseNextInvoiceNumber() {
    this.LocalStorage.set('nextInvoiceNumber', (this.getNextInvoiceNumber() + 1).toString());
  }

  decreaseNextInvoiceNumber() {
    const nextInvoiceNumber = this.getNextInvoiceNumber();
    if (nextInvoiceNumber > 1) {
      this.LocalStorage.set('nextInvoiceNumber', (nextInvoiceNumber - 1).toString());
    }
  }

  public setActiveEmployee(employeeData: Employee) {
    this.activeEmployee = employeeData ? new Employee(employeeData) : null;
    this.logger.debug('setActiveEmployee', {
      firstName: this.activeEmployee?.firstName,
      lastName: this.activeEmployee?.lastName,
      employeeUuid: this.activeEmployee?.uuid,
    });
    this.activeEmployeeUpdateEvent.emit(employeeData);
    this.LocalStorage.set(`${StorageKeys.activeStore}${this.loggedCompanyData.getValue().uuid}`, JSON.stringify(this.activeStore));
  }

  public getActiveEmployee(): Employee {
    return this.activeEmployee ? new Employee(this.activeEmployee) : this.activeEmployee;
  }

  public setActiveStore(storeData: Store) {
    this.activeStore = storeData ? new Store(storeData) : null;
    this.activeStoreUpdateEvent.emit(storeData);
  }

  public getActiveStore(): Store {
    return this.activeStore;
  }

  public setStoreList(stores: Store[]) {
    this.storeList$.next(stores);
  }

  public getStoreList() {
    return this.storeList$;
  }

  public setLoggedCompanyData(companyData: any) {
    this.loggedCompanyData.next(new Company(companyData));
    if (companyData !== null) {
      this.getCompanyLogoBase64(companyData)
        .then((data) => {
          this.saveCompanyLogo(data);
        })
        .catch(() => {
          this.removeCompanyLogo();
        });
    }

    const savedStoreData = this.LocalStorage.get(`${StorageKeys.activeStore}${this.loggedCompanyData.getValue().uuid}`);
    if (savedStoreData && savedStoreData !== 'null') {
      this.activeStore = new Store(JSON.parse(savedStoreData));
    }
  }

  public getLoggedCompanyData(): Company {
    return this.loggedCompanyData.getValue();
  }

  public setMigratedStatus(isMigrated: boolean): void {
    let company: Company = this.loggedCompanyData.getValue();
    if (company.isMigrated !== isMigrated) {
      company.isMigrated = isMigrated;
      this.setLoggedCompanyData(company);
    }
  }

  public createAuthorizationHeader() {
    let headers = new HttpHeaders();
    const { PosId, RequestId, AppVersion, Authorization, CompanyId, Target, DeviceFingerprint } = RequestHeaderTypes;
    const loggedCompanyData = this.loggedCompanyData.getValue();
    headers = headers.append(PosId, this.getDeviceIdentifier());
    headers = headers.append(RequestId, this.UuidService.generate());
    headers = headers.append(AppVersion, APP_INFO.APP_VERSION);
    headers = headers.append(DeviceFingerprint, this.getDeviceFingerprint());
    if (this.loggedUserData && this.loggedUserData.accessToken) {
      headers = headers.append(Authorization, `Bearer ${this.loggedUserData.accessToken}`);
      if (loggedCompanyData) {
        headers = headers.append(CompanyId, loggedCompanyData.uuid);
      }
    }
    if (loggedCompanyData && loggedCompanyData.isMigrated) {
      headers = headers.append(Target, 'paymash2');
    }
    return headers;
  }

  public loadViaWorker(config: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let id: any, msgFn: any;
      msgFn = (e: any) => {
        if (e && e.data && (e.data.status === 200 || e.data.status === 201)) {
          resolve(e.data);
        } else {
          reject(e.data);
        }
        this.RequestsWbService.terminate(id);
      };

      id = this.RequestsWbService.load(config, msgFn, reject);
    });
  }

  @ConvertDataForRequest()
  public doSecureRequest(url: string, type: string, data?: Object, optionsData?: ISecureRequestOptions): Promise<SecuredResponse> {
    let dataToSend = data;
    return new Promise((resolve, reject) => {
      let useHttp: boolean = false;
      let showLoading = false;
      let requestTimeout;
      if (optionsData && optionsData.showLoading) {
        showLoading = optionsData.showLoading;
      }
      if (optionsData && optionsData.useHttp) {
        useHttp = optionsData.useHttp;
      }
      if (optionsData && optionsData.timeout) {
        requestTimeout = optionsData.timeout;
      }

      if (showLoading) this.LoadingService.showLoadingItem();
      this.LoadingService.syncStart();
      const headers = this.createAuthorizationHeader();
      const options: any = {
        headers,
        observe: 'response',
      };
      let reresponseType: string = '';
      if (optionsData && optionsData.responseTypeData) {
        options.responseType = optionsData.responseTypeData;
        reresponseType = optionsData.responseTypeData.toLowerCase();
      }
      if (optionsData && optionsData.body) {
        options.body = optionsData.body;
      }
      let request;
      if (
        !RequestsWbService.supported ||
        (window.cordova && this.Device.platform.toLowerCase() === 'ios' && this.Device.version.toString().split('.')[0] === '9') ||
        useHttp
      ) {
        request = this.createRequestAsPromise(url, type, options, dataToSend, requestTimeout);
      } else {
        requestTimeout = requestTimeout || PAYMASH_PROFILE.requestTimeout.normal;
        let config: any = {
          method: type,
          url: url,
          data: data,
          options: options,
          headers: this.createAuthorizationHeader(),
          responseType: reresponseType,
          timeout: requestTimeout,
        };
        request = this.loadViaWorker(config);
      }
      let requestId;
      const startTime = performance.now();
      try {
        requestId = headers.get(RequestHeaderTypes.RequestId);
        this.logger.trace(`Begin ${type} ${url}`, { requestId });
      } catch (error) {
        this.logger.error(error, 'doSecureRequest');
      }
      request
        .then((data: HttpResponse<any>) => {
          try {
            const endTime = performance.now();
            const requestDuration = endTime - startTime;
            this.logger.trace(`Completed ${type} ${url}`, {
              requestId,
              requestDuration: requestDuration.toFixed(2),
              requestStatus: data.status,
            });
          } catch (error) {
            this.logger.error(error, 'doSecureRequest');
          }
          if (showLoading) {
            this.LoadingService.hideLoadingItem();
          }
          this.LoadingService.syncFinish();
          resolve(new SecuredResponse(data));
        })
        .catch((data: HttpResponse<any>) => {
          if (data instanceof TimeoutError) {
            data = this.createTimeoutErrorResponse(url);
          }
          try {
            const endTime = performance.now();
            const requestDuration = endTime - startTime;
            this.logger.error(
              data,
              `Completed ${type} ${url}`,
              { level: ErrorLevel.Medium },
              { requestId, requestDuration: requestDuration.toFixed(2), requestStatus: data.status }
            );
          } catch (error) {
            this.logger.error(error, 'doSecureRequest');
          }
          let parsedData;
          this.LoadingService.syncFinish();
          try {
            parsedData = data['error'];
          } catch (err) {}
          if (
            data.status === 401 &&
            parsedData &&
            parsedData.type !== 'Authentication' &&
            parsedData.type !== 'AccessToken' &&
            this.loggedUserData &&
            this.loggedUserData.refreshToken
          ) {
            this.refreshToken(url, type, dataToSend)
              .then((data: HttpResponse<any>) => {
                if (showLoading) this.LoadingService.hideLoadingItem();
                resolve(new SecuredResponse(data));
              })
              .catch((data: HttpResponse<any>) => {
                if (showLoading) this.LoadingService.hideLoadingItem();
                reject(new SecuredResponse(data));
              });
          } else {
            if (data.status === 402) {
              const badRefreshTokenMessage = { clearLocalDatabase: false };
              this.badRefreshToken.next(badRefreshTokenMessage);
            }
            if (showLoading) this.LoadingService.hideLoadingItem();
            if (data.status === 429) {
              const retryAfter = parseInt(data.headers.get(RequestHeaderTypes.RetryAfter)) || PAYMASH_PROFILE.requestTimeout.retryAfter;
              setTimeout(() => {
                reject(new SecuredResponse(data));
              }, retryAfter * 1000);
            } else {
              reject(new SecuredResponse(data));
            }
          }
        });
    });
  }

  public refreshToken(url: string, type: string, dataToSend: Object) {
    return new Promise((resolve, reject) => {
      const options = {
        headers: this.createAuthorizationHeader(),
        observe: 'response',
      };
      const tokenData = {
        refreshToken: this.loggedUserData.refreshToken,
        deviceIdentifier: this.getDeviceIdentifier(),
        deviceFingerprint: this.getDeviceFingerprint(),
      };
      const { normal } = PAYMASH_PROFILE.requestTimeout;
      this.logger.debug(`refreshToken:createRequestAsPromise:data = ${stringifySafety(options.headers, ['4'])}`);
      this.createRequestAsPromise(`${SERVER_CONFIG.API_URL}authorization/accessToken`, 'post', options, tokenData, normal)
        .then((data: HttpResponse<any>) => {
          const newLoggedUserData = { ...this.loggedUserData };
          newLoggedUserData.accessToken = data.body['properties']['accessToken'];
          this.setLoggedUserData(newLoggedUserData);
          const newHeaders = this.createAuthorizationHeader();
          if (url) {
            const request = this.createRequestAsPromise(url, type, { headers: newHeaders, observe: 'response' }, dataToSend);
            resolve(request);
            return;
          }
          resolve(data);
        })
        .catch((data: HttpResponse<any>) => {
          this.logger.error(data, 'refreshToken:createRequestAsPromise', { level: ErrorLevel.Medium });
          if (data instanceof TimeoutError) {
            data = this.createTimeoutErrorResponse(url);
          }
          if ((data && (data.status === 400 || data.status === 401)) || data.status === 402) {
            const badRefreshTokenMessage = {
              clearLocalDatabase: false,
              isExpiredVersion: false,
            };
            try {
              const response: any = { ...data };
              const error: ServerError = response.error;
              if (response && response.clearLocalDatabase) {
                badRefreshTokenMessage.clearLocalDatabase = response.clearLocalDatabase;
              }
              if (response && error && error.code) {
                badRefreshTokenMessage.isExpiredVersion = error.code === SERVER_RESPONSE_ERRORS.VERSION_IS_EXPIRED;
              }
            } catch (err) {}
            this.badRefreshToken.next(badRefreshTokenMessage);
          }
          reject(data);
        });
    });
  }

  public doSecureRequestObservable(
    url: string,
    type: 'get' | 'post' | 'delete' | 'put',
    data?: Object,
    optionsData?: ISecureRequestOptions
  ): Observable<SecuredResponse> {
    return new Observable((observer) => {
      this.doSecureRequest(url, type, data, optionsData)
        .then((result) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => observer.error(error));
    });
  }

  public resetCompany() {
    const removedTypesPromises = Object.values(UPDATES_TYPES).map((value) => this.DbDaoService.deleteDatabase(value.type));
    this.GoogleAnalyticsService.trackEvent('ResetCompany', 'companyWasResetted');
    return Promise.all(removedTypesPromises);
  }

  public checkLicenseExpiration(licenseInfo: License): boolean {
    if (!licenseInfo.isActive) {
      const [, url] = PAYMASH_PROFILE.SERVER_URL.split('//');
      this.showError('license_expired_title', 'license_expired_description', { url });
      return false;
    }
    if (!licenseInfo.isPosActivated) {
      this.showError('license_pos_not_included_title', 'license_pos_not_included_message');
      return false;
    }
    if (licenseInfo.isNumberOfConcurrentUsersExceeded) {
      this.showError('license_warning_title', 'license_warning_description');
      return true;
    }
    return true;
  }

  public async showError(titleKey: string, messageKey: string, messageProps: Object = {}) {
    if (!this.errorAlert) {
      this.errorAlert = await this.AlertService.create({
        header: this.TranslateService.instant(titleKey),
        subHeader: this.TranslateService.instant(messageKey, messageProps),
        buttons: ['OK'],
      });
      this.errorAlert
        .onDidDismiss()
        .then(() => (this.errorAlert = null))
        .catch((err) => this.logger.error(err, 'showError:onDidDismiss'));

      this.errorAlert.present().catch((err) => this.logger.error(err, 'showError:present'));
    }
  }

  public logout() {
    this.logoutEvent.emit();
  }

  public observableCompanyProperties(...properties: CompanyProperties[]): Observable<Company> {
    return this.loggedCompanyData.pipe(
      pairwise(),
      filter((pairs) => {
        if (pairs[0] && pairs[1] && !!properties.length) {
          return properties.some((property) => pairs[0][property] !== pairs[1][property]);
        }
        return false;
      }),
      map((pairs) => pairs[1])
    );
  }

  private createRequestAsPromise(
    url: string,
    type: string,
    options: any,
    data?: Object,
    requestTimeout?: number
  ): Promise<HttpResponse<any>> {
    requestTimeout = requestTimeout || PAYMASH_PROFILE.requestTimeout.max;
    const request: Observable<HttpResponse<any>> = data ? this.http[type](url, data, options) : this.http[type](url, options);
    const promiseRequest = request.pipe(timeout(requestTimeout)).toPromise();
    return promiseRequest;
  }

  private createTimeoutErrorResponse(url: string) {
    return new HttpResponse({
      status: 408,
      body: 'Request Timeout',
      url,
    });
  }

  private getFingerprint() {
    return FingerprintPlugin.getFingerprint('Unique').then((result) => {
      this.logger.debug(`getFingerprint ${stringifySafety(result)}`);
      return result.fingerprint;
    });
  }
}

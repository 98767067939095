import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Employee } from '@pos-common/classes/employee.class';
import { ProductCategory } from '@pos-common/classes/product-category.class';
import { ProductItemGroup } from '@pos-common/classes/product-item-group.class';
import { ProductItem } from '@pos-common/classes/product-item.class';
import { ProductList } from '@pos-common/classes/product-list.class';
import { ProductVariant } from '@pos-common/classes/product-variant.class';
import { Product } from '@pos-common/classes/product.class';
import { Store } from '@pos-common/classes/store.class';
import { PRODUCT_VARIANT_LIST_TYPE } from '@pos-common/constants';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { IInventoryEvent } from '@pos-common/interfaces/inventory-event.interface';
import { ProductVariantProvider } from '@pos-common/services/resources/product-variant-db-entity.provider';
import { AppointmentModalService } from '@pos-common/services/system/appointment-modal/appointment-modal.service';
import { AppointmentService } from '@pos-common/services/system/appointment/appointment.service';
import { CartService } from '@pos-common/services/system/cart.service';
import { CollectionViewService } from '@pos-common/services/system/collection-view.service';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { TableEnforceService } from '@pos-common/services/system/table-select-enforcement.service';
import { TippingService } from '@pos-common/services/system/tipping/tipping.service';
import { UpdatesService } from '@pos-common/services/system/updates.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { ProductDetailsModal } from 'pages/collection-view/product-details-modal/product-details-modal.component';
import { from, innerJoin, notEquals, property, Query, query, oneOf, by } from '@paymash/capacitor-database-plugin';
import { from as rxFrom, merge, Observable, of } from 'rxjs';
import { concatMap, delay, map, takeWhile } from 'rxjs/operators';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { DbResponse } from '@pos-common/services/db/db-dao.utils';
import { stringifySafety } from '@pos-common/services/system/logger';

@Component({
  selector: 'pos-virtual-product-list',
  templateUrl: './virtual-product-list.component.html',
  styleUrls: ['./virtual-product-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class VirtualProductListComponent implements OnInit, AfterViewInit {
  @Input() parentScroll: Element;
  @Input() types: string[] = [];
  @ViewChild('virtualScroll') virtualScroll: VirtualScrollerComponent;
  public cellsQuantity: number;
  public cellWidth: number;
  public productItemList: ProductItem[] = [];
  private productList = new ProductList();
  private requiredUpdatesTypes = [UPDATES_TYPES.Product, UPDATES_TYPES.ProductCategory];
  private activeStore: Store = null;
  private resizeListener: () => void;
  private readonly logger = this.logService.createLogger('VirtualProductListComponent');

  get isServiceList() {
    return this.types.some((type) => type === PRODUCT_TYPES.SERVICE);
  }

  constructor(
    private collectionViewService: CollectionViewService,
    private modalService: ModalService,
    private dbDaoService: DbDaoService,
    private productVariantProvider: ProductVariantProvider,
    private updatesService: UpdatesService,
    private cartService: CartService,
    private tableEnforceService: TableEnforceService,
    private invoicesService: InvoicesService,
    private cdr: ChangeDetectorRef,
    private tippingService: TippingService,
    private renderer: Renderer2,
    private securityService: SecurityService,
    private appointmentModalService: AppointmentModalService,
    private appointmentService: AppointmentService,
    private platformService: PlatformService,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    for (let i = 0; i < this.requiredUpdatesTypes.length; i++) {
      const currentUpdateType = this.requiredUpdatesTypes[i];
      this['list' + currentUpdateType['type']] = [];
      this.subSinkService.sink = this.updatesService.updatesEmitters[currentUpdateType['type']].subscribe((data) => {
        if (data) {
          this.handleUpdateData(data, currentUpdateType['type']);
        }
      });
    }

    this.activeStore = this.securityService.getActiveStore();
    this.setQuantity(this.cellsQuantity);
    this.resizeListener = this.renderer.listen(window, 'resize', this.updateCellParams.bind(this));
  }

  ngAfterViewInit(): void {
    this.updateCellParams();
    this.getProductsAndCategoriesFromDb();
  }

  ngOnDestroy(): void {
    this.resizeListener();
    this.collectionViewService.setProducVariantModalData(null);
  }

  private setQuantity(cellsQuantity: number) {
    this.cellsQuantity = cellsQuantity;
  }

  private getProductsAndCategoriesFromDb(categoryUuid?: string) {
    this.subSinkService.sink = merge(
      this.getAllCategoriesFromDb(categoryUuid).pipe(
        map((categoriesListData) => ({ categoriesListData: categoriesListData.data, productListData: undefined }))
      ),
      this.getAllProductsFormDb(categoryUuid).pipe(
        concatMap((productListData, idx) => (idx === 0 ? of(productListData) : of(productListData).pipe(delay(500)))),
        map((productListData) => ({ productListData, categoriesListData: undefined })),
        takeWhile(() => {
          return this.productList.hasOpenCategory(categoryUuid);
        })
      )
    ).subscribe(
      ({ categoriesListData, productListData }) => {
        if (categoriesListData) {
          this.addCategoriesToList(categoriesListData, categoryUuid);
        }
        if (productListData) {
          this.addProductsToList(productListData, categoryUuid);
        }
        this.updateProductItemList();
      },
      (error) => {
        this.logger.error(error, 'getProductsAndCategoriesFromDb');
      }
    );
  }

  private getAllCategoriesFromDb(categoryUuid: string): Observable<DbResponse> {
    if (!categoryUuid || (categoryUuid && categoryUuid === 'root')) {
      categoryUuid = null;
    }
    const queryParams: Query = { visible: true, parentCategoryUuid: categoryUuid, deleted: false };
    return rxFrom(this.dbDaoService.getAllData(UPDATES_TYPES.ProductCategory.type, queryParams));
  }

  private getAllProductsFormDb(productCategoryUuid?: string): Observable<any> {
    productCategoryUuid = productCategoryUuid || 'root';

    const activeStore = this.securityService.getActiveStore();

    const queryParams = query({
      'p.visible': true,
      'p.deleted': false,
      'p.ext_type': null,
      'ext.ext_type': 'category_store',
      'ext.categoryUuid': productCategoryUuid,
    });

    if (activeStore) {
      queryParams.push({
        'ext.storeUuid': activeStore.uuid,
      });
    }

    if (this.tippingService.isTipping && this.tippingService.productUuid) {
      queryParams.push({ 'p.uuid': notEquals(this.tippingService.productUuid) });
    }
    if (this.types.length) {
      queryParams.push({ 'p.type': oneOf(...this.types) });
    }

    return this.dbDaoService.getAllDataChunked(from(UPDATES_TYPES.Product.type, 'p'), queryParams, {
      join: [innerJoin(UPDATES_TYPES.Product.type, 'ext', { 'ext.productUuid': property('p.uuid') })],
      chunkSize: 15,
      order: [by('p.fieldToSort')],
      select: ['p.uuid', 'p.visible', 'p.isNew', 'p.isSale', 'p.bgColor', 'p.name', 'p.images', 'p.wasPrice', 'p.price'],
    });
  }

  private addProductsToList(products: Product[], categoryUuid?: string) {
    const productList = this.sortData(products);
    this.productList.addProducts(productList, categoryUuid);
    this.notifyAboutProductDublicates(productList, categoryUuid);
  }

  private addCategoriesToList(productCategories: ProductCategory[], categoryUuid?: string) {
    productCategories = this.sortData(productCategories, true);
    this.productList.addProductCategories(productCategories, categoryUuid);
  }

  private sortData(data: any[], isCategory: boolean = false) {
    return [...data].sort((a, b) => {
      const nameA = a.name?.toLowerCase();
      const nameB = b.name?.toLowerCase();
      const nameOrder = nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
      if (isCategory) {
        const positionOrder = a?.positionInParentProductCategory - b?.positionInParentProductCategory;
        return positionOrder || nameOrder;
      }
      return nameOrder;
    });
  }

  handleTouchStart(event: IInventoryEvent) {
    const { uuid } = event.dataset;
    const productItem = this.findProductItem(uuid);
    if (productItem && !productItem.isCategory) {
      this.productLongClick(productItem);
    }
  }

  handleTouchEnd(event: IInventoryEvent) {
    const productItem = this.findProductItem(event.dataset.uuid);
    if (!productItem) {
      return;
    }
    if (!productItem.isCategory) {
      this.productClick(productItem, event.eventTarget);
      return;
    }
    const expanded = !productItem.expanded;
    const { uuid: categoryUuid } = productItem;
    if (expanded) {
      this.productList.closeCategory(categoryUuid);
      this.productList.openCategory(categoryUuid);
      this.getProductsAndCategoriesFromDb(categoryUuid);
    } else {
      this.productList.hideProductsAndCategory(categoryUuid);
      this.updateProductItemList();
    }
    productItem.expanded = expanded;
    return;
  }

  private findProductItem(uuid: string) {
    const { viewPortItems } = this.virtualScroll;
    for (let i = 0; i < viewPortItems.length; i++) {
      const productItems: ProductItemGroup = viewPortItems[i];
      const productItem = productItems.productItemList.find((p) => p.uuid === uuid);
      if (productItem) {
        return productItem;
      }
    }
    return null;
  }

  private findProductFromDatabase(uuid: string): Promise<Product> {
    return this.dbDaoService.getDataByUUID(UPDATES_TYPES.Product.type, uuid).then(({ data }) => new Product(data));
  }

  private updateProductItemList() {
    this.productItemList = this.productList.getProductItemGroups(this.cellsQuantity);
    this.cdr.detectChanges();
  }

  private async productClick(productItem: ProductItem, eventTarget: any) {
    if (this.isDisabledSelectProduct()) {
      return;
    }

    if (eventTarget) {
      this.addRippleEffect(eventTarget);
    }
    const { productCategoryUuid } = productItem;
    const product = await this.findProductFromDatabase(productItem.data.uuid);
    if (!product.hasCustomOptions && !product.forcePriceInputOnSale) {
      this.productVariantProvider
        .getByUuid(product.variants[0].uuid)
        .toPromise()
        .then((variant) => {
          if (this.isServiceList) {
            const defaultEmployee = this.appointmentService.getDefaultEmployee();
            if (defaultEmployee) {
              this.addServiceToCard(product, defaultEmployee);
              return;
            }
            this.openProductVariantModal(product, productCategoryUuid, eventTarget);
            return;
          }
          this.addProductToCart(variant, product, productCategoryUuid, '');
        })
        .catch((err) => this.logger.error(err, 'productClick:DbDaoService:getDataByUUID:'));
    } else {
      this.productVariantProvider
        .getListByParams({ productUuid: product.uuid })
        .toPromise()
        .then((variants) => {
          if ((variants && variants.length > 1) || product.forcePriceInputOnSale || this.isServiceList) {
            this.openProductVariantModal(product, productCategoryUuid, eventTarget);
            return;
          }
          const variant = new ProductVariant(variants[0]);
          const shortName = variant.getVariantConcatenatedName();
          this.addProductToCart(variant, product, productCategoryUuid, shortName);
        })
        .catch(() => {
          this.openProductVariantModal(product, productCategoryUuid, eventTarget);
        });
    }
  }

  private isDisabledSelectProduct() {
    return (
      !this.isServiceList &&
      (this.tableEnforceService.checkForTableEnforcementAndShowAlert() || this.cartService.checkPartialPaymentInInvoice())
    );
  }

  private async productLongClick(productItem: ProductItem) {
    if (this.cartService.checkPartialPaymentInInvoice()) {
      return;
    }
    const product = await this.findProductFromDatabase(productItem.uuid);
    if (this.isServiceList) {
      return this.openCustomServiceModal(product);
    }
    this.openProductDetailsModal(product, productItem.productCategoryUuid);
  }

  private async openProductDetailsModal(product: Product, productCategoryUuid: string) {
    const productDetailsModal = await this.modalService.presentModal(ProductDetailsModal, {
      product,
      productCategory: productCategoryUuid,
      source: 'collection',
    });
    await productDetailsModal.present();
  }

  private openCustomServiceModal(product: Product) {
    let service = this.appointmentService.makeServiceToAddData(product, product.price, null);
    service = this.appointmentService.createService(service);
    this.appointmentModalService
      .openCustomServiceModal(service, true)
      .then((data) => this.appointmentService.setCustomService(service, data));
  }

  private addServiceToCard(product: Product, employee: Employee) {
    let service = this.appointmentService.makeServiceToAddData(product, product.price, employee);
    service = this.appointmentService.createService(service);
    this.appointmentService.addServiceToActiveAppointment(service);
  }

  private openProductVariantModal(product: Product, productCategoryUuid: string, eventTarget: any) {
    const listType = this.isServiceList ? PRODUCT_VARIANT_LIST_TYPE.SERVICES : PRODUCT_VARIANT_LIST_TYPE.PRODUCTS;
    this.collectionViewService.setProducVariantModalData({
      product,
      eventTarget,
      listType,
      productCategoryUuid,
    });
  }

  private addProductToCart(variant: ProductVariant, product: Product, productCategoryUuid: string, productShortName: string) {
    const dataToAdd = this.invoicesService.makeInvoiceEntryToAddData(variant, product, productShortName, productCategoryUuid);
    dataToAdd.image = this.cartService.getEntryImage(product, variant);
    this.cartService.addEntry(dataToAdd, product.type, 1);
  }

  private handleUpdateData(updateData, type: string) {
    const factory = (type: string, data: any) => {
      switch (type) {
        case UPDATES_TYPES.Product.type:
          return new Product(data);
        case UPDATES_TYPES.ProductCategory.type:
          return new ProductCategory(data);
      }
    };

    for (let i = 0; i < updateData.length; i++) {
      const newItem: any = factory(type, updateData[i]['data']);
      const category = newItem instanceof Product ? newItem.productCategoriesUuid.replace(',', '') : newItem.parentCategoryUuid;
      const hasItem = this.productList.has(type, newItem.uuid);
      if (newItem instanceof Product) {
        newItem.visible = newItem.storeUuids.some((storeUuid) => storeUuid === this.activeStore.uuid);
      }

      if (hasItem) {
        this.productList.update(type, newItem, category);
        this.collectionViewService.setNewProductItemEvent(newItem);
      } else {
        this.productList.add(type, [newItem], category);
      }
    }
    this.updateProductItemList();
  }

  private updateCellParams() {
    const padding = this.platformService.isMobile ? 0 : 370;
    const inventoryAreaWidth = window.innerWidth - padding;
    if (inventoryAreaWidth < 0) {
      return;
    }
    const minCellWidth = this.platformService.isMobile ? 105 : 115;
    const cellsQuantity = this.collectionViewService.calculateCellsQuantityInRow(inventoryAreaWidth, minCellWidth);
    const itemWidth = inventoryAreaWidth / cellsQuantity;
    this.cellWidth = Math.round((itemWidth / inventoryAreaWidth) * 100);
    if (cellsQuantity !== this.cellsQuantity) {
      this.setQuantity(cellsQuantity);
      this.updateProductItemList();
      return;
    }
    this.cdr.detectChanges();
  }

  private addRippleEffect(eventTarget: any) {
    const rippleContainer = eventTarget.querySelector('.ripple-container');
    if (rippleContainer) {
      this.renderer.addClass(rippleContainer, 'animate');
      setTimeout(() => this.renderer.removeClass(rippleContainer, 'animate'), 100);
    }
  }

  private notifyAboutProductDublicates(productList: Product[], categoryUuid: string = 'root') {
    const dublicates = productList.filter((product, index, array) => array.indexOf(product) !== index);
    if (dublicates.length) {
      dublicates.forEach((product) => this.logger.debug('Dublicate product, category', { productUuid: product.uuid, categoryUuid }));
    }
  }
}

import { ProductCategory } from './product-category.class';
import { ProductItem } from './product-item.class';
import { Product } from './product.class';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { ProductItemGroup } from '@pos-common/classes/product-item-group.class';
import { ProductOrCategoryType } from '@pos-common/types/product-or-category.type';

export class ProductList {
  private readonly rootCategory = 'root';
  private openCategories: string[] = [];
  private productCategories = new Map<string, ProductItem[]>();
  private products = new Map<string, ProductItem[]>();
  private typeMap = new Map<string, any>();

  constructor() {
    this.productCategories.set(this.rootCategory, []);
    this.products.set(this.rootCategory, []);
    this.openCategories.push(this.rootCategory);
    this.typeMap.set(UPDATES_TYPES.Product.type, this.products);
    this.typeMap.set(UPDATES_TYPES.ProductCategory.type, this.productCategories);
  }

  add(type: string, products: ProductOrCategoryType[], categoryUuid?: string) {
    categoryUuid = this.getCategoryUuid(categoryUuid);
    const productData = this.typeMap.get(type);
    const productItems = productData.get(categoryUuid);
    const activeProducts = products.filter((product) => !product.deleted);
    if (!productItems || !activeProducts.length) {
      return;
    }
    const isCategory = this.isCategory(type);
    const newProductItems = [...productItems, ...activeProducts.map((product) => new ProductItem(product, categoryUuid, isCategory))];
    this.sortData(newProductItems);
    productData.set(categoryUuid, newProductItems);
  }

  update(type: string, product: ProductOrCategoryType, categoryUuid?: string) {
    const productData: Map<string, any> = this.typeMap.get(type);
    let productItems = productData.get(categoryUuid);

    if (!categoryUuid && !productItems) {
      productData.forEach((products) => {
        const index = products.findIndex((productItem) => productItem.data.uuid === product.uuid);
        if (index > -1) {
          productItems = products;
        }
      });
    }

    if (productItems) {
      const index = productItems.findIndex((productItem) => productItem.data.uuid === product.uuid);
      if (index > -1) {
        if (!product.visible || product.deleted) {
          if (type === UPDATES_TYPES.ProductCategory.type) {
            this.hideProductsAndCategory(product.uuid);
          }
          productItems.splice(index, 1);
          productData.set(categoryUuid, productItems);
        } else {
          productItems[index].data = product;
        }
      }
    }
  }

  has(type: string, uuid: string) {
    const productData = this.typeMap.get(type);
    return !!this.findByUuid(productData, uuid);
  }

  find(type: string, uuid: string) {
    const productData = this.typeMap.get(type);
    return this.findByUuid(productData, uuid);
  }

  private findByUuid(data: Map<string, ProductItem[]>, uuid: string) {
    const values = data.values();
    for (let i = 0; i < data.size; i++) {
      const productItems: ProductItem[] = values.next().value;
      const result = productItems.find((productItem) => productItem.uuid === uuid);
      if (result) {
        return result;
      }
    }
    return null;
  }

  addProducts(products: Product[], categoryUuid?: string) {
    categoryUuid = this.getCategoryUuid(categoryUuid);
    const saveProdcutItems = this.products.get(categoryUuid) || [];
    const productItems = products.map((p) => new ProductItem(p, categoryUuid, false));
    this.products.set(categoryUuid, [...saveProdcutItems, ...productItems]);
  }

  addProductCategories(productCategories: ProductCategory[], categoryUuid?: string) {
    categoryUuid = this.getCategoryUuid(categoryUuid);
    const productCategoriesItems = productCategories.map((p) => new ProductItem(p, categoryUuid, true));
    this.productCategories.set(categoryUuid, productCategoriesItems);
  }

  hideProductsAndCategory(categoryUuid: string) {
    const index = this.openCategories.findIndex((category) => category === categoryUuid);
    if (index === 0) {
      this.openCategories = this.openCategories.slice(0, 1);
    } else if (index > 0) {
      const closeOpenCategory = this.openCategories.splice(index);
      closeOpenCategory.forEach((uuid) => {
        this.deleteDataByKey(this.productCategories, uuid);
        this.deleteDataByKey(this.products, uuid);
      });
    }
  }

  closeCategory(uuid: string) {
    uuid = this.getCategoryUuid(uuid);
    const openCategoryUuid = this.getOpenCategoryUuid(uuid);
    if (openCategoryUuid) {
      this.hideProductsAndCategory(openCategoryUuid);
    }
  }

  private getOpenCategoryUuid(uuid: string) {
    const values = this.productCategories.values();
    for (let i = 0; i < this.productCategories.size; i++) {
      const productItems: ProductItem[] = values.next().value;
      const hasCategoryUuid = productItems.some((productItem) => productItem.uuid === uuid);
      const hasOpenCategory = productItems.some((productItem) => productItem.expanded);
      if (hasCategoryUuid && hasOpenCategory) {
        productItems.forEach((productItem) => (productItem.expanded = false));
        return this.openCategories[i + 1];
      }
    }
  }

  openCategory(categoryUuid: string) {
    const hasCategories = this.hasOpenCategory(categoryUuid);
    if (!hasCategories) {
      this.openCategories.push(categoryUuid);
    }
  }

  hasOpenCategory(categoryUuid: string) {
    categoryUuid = this.getCategoryUuid(categoryUuid);
    return this.openCategories.some((category) => category === categoryUuid);
  }

  private deleteDataByKey(data: Map<string, ProductItem[]>, categoryUuid: string) {
    const hasCategoryKey = data.has(categoryUuid);
    if (hasCategoryKey) {
      data.delete(categoryUuid);
    }
  }

  private isCategory(type: string) {
    return type === UPDATES_TYPES.ProductCategory.type;
  }

  getProductItemGroups(size: number) {
    return this.openCategories.reduce((result, category, currentIndex) => {
      const oneProductItemGroup = this.getOneProductItemGroup(category, size, currentIndex);
      if (category !== this.rootCategory) {
        const categoryIndex = result.findIndex((productItemGroup: ProductItemGroup) => {
          for (let index = 0; index < productItemGroup.productItemList.length; index++) {
            const element = productItemGroup.productItemList[index];
            if (element && element.uuid === category) {
              return true;
            }
          }
          return false;
        });
        if (categoryIndex > -1) {
          result.splice(categoryIndex + 1, 0, ...oneProductItemGroup);
          return result;
        }
      }
      return [...result, ...oneProductItemGroup];
    }, []);
  }

  private sliceGroups(data: any[], size: number, order: number): ProductItemGroup[] {
    const result = [];
    if (data) {
      for (let i = 0; i < Math.ceil(data.length / size); i++) {
        result[i] = new ProductItemGroup(data.slice(i * size, i * size + size), order);
      }
    }
    return result;
  }

  private getOneProductItemGroup(categoryUuid: string, size: number, order: number) {
    const productCategories = this.productCategories.get(categoryUuid);
    const sliceProductCategory = this.sliceGroups(productCategories, size, order);
    const products = this.products.get(categoryUuid);
    const sliceProducts = this.sliceGroups(products, size, order);
    return [...sliceProductCategory, ...sliceProducts];
  }

  private sortData(data: ProductItem[]) {
    data.sort((a, b) => {
      const nameA = a.data?.name?.toLowerCase();
      const nameB = b.data?.name?.toLowerCase();
      return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
    });
  }

  private getCategoryUuid(categoryUuid: string) {
    return categoryUuid || this.rootCategory;
  }
}

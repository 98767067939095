import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteNavigationService } from '../services/system/route-navigation/route-navigation.service';
import { SecurityService } from '../services/system/security.service';
import { ROUTE_URLS } from '../constants/route-urls.const';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { LogService } from '../services/system/logger/log.service';

@Injectable()
export class AuthGuard implements CanActivate {
  private readonly logger = this.logService.createLogger('AuthGuard');
  constructor(
    private routeNavigationService: RouteNavigationService,
    private navController: NavController,
    private securityService: SecurityService,
    private logService: LogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const loggedUserData = this.securityService.getLoggedUserData();
    const isFirstRoute = this.routeNavigationService.isFirstRoute();
    const { settings, login, employee, invoicesWebshop } = ROUTE_URLS;

    if (!loggedUserData) {
      if (!isFirstRoute && state.url === settings) {
        return true;
      }
      this.navController.navigateBack(login).catch((err) => this.logger.error(err, 'canActivate:navigateBack:login'));
      return false;
    }
    if (isFirstRoute && state.url !== employee) {
      this.navController
        .navigateRoot(employee, { animated: true, animationDirection: 'back' })
        .catch((err) => this.logger.error(err, 'canActivate:navigateRoot:employee'));
      return false;
    }
    const company = this.securityService.getLoggedCompanyData();
    if (!isFirstRoute && state.url === invoicesWebshop && !company?.isWebshopEnabled) {
      return false;
    }
    return true;
  }
}

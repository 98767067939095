import { IPrinterReceiptItem } from '../../interfaces';
import { IReceiptItem } from '../../interfaces/receipt-item.interface';

export class SunmiReceiptItem implements IPrinterReceiptItem<IReceiptItem> {
  getReceipt(receipt: IReceiptItem[]): IReceiptItem[] {
    return receipt.reduce((newReceipt, item) => {
      if (item.textSize) {
        const textSize = this.getTextSize(item.textSize);
        item = { ...item, textSize };
      }
      newReceipt.push(item);
      return newReceipt;
    }, []);
  }

  private getTextSize(textSize: number) {
    return textSize === 2 ? 48 : 26;
  }
}

import { HttpClient } from '@spryrocks/capacitor-http-plugin';
import { Injectable } from '@angular/core';
import { SendRequestOptions, ErrorHandler } from '../../adyen-types';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LogService } from '@pos-common/services/system/logger';
import { RequestHeaderTypes } from '@pos-common/constants';

@Injectable()
export class AdyenApiHelper {
  private readonly logger = this.logService.createLogger('AdyenApiHelper');
  constructor(private readonly localStorage: LocalStorage, private readonly logService: LogService) {}

  get<TResponse>(endpoint: string, options?: { errorHandler?: ErrorHandler }) {
    return this.sendRequest<TResponse>({
      endpoint,
      method: 'get',
      errorHandler: options?.errorHandler,
    });
  }

  post<TResponse>(endpoint: string, data: unknown, options?: { errorHandler?: ErrorHandler }) {
    return this.sendRequest<TResponse>({
      endpoint,
      method: 'post',
      data,
      errorHandler: options?.errorHandler,
    });
  }

  private async sendRequest<TResponse>({ endpoint, method, data, errorHandler }: SendRequestOptions): Promise<TResponse> {
    this.logger.info('Send request', { endpoint, method, data });
    const apiKey = this.getApiKey();
    const headers = this.createHeaders(apiKey);
    endpoint = this.getNewEndpoint(endpoint);
    const response = await HttpClient.sendRequest({
      url: endpoint,
      method,
      data,
      headers,
      responseType: 'json',
    }).catch((error) => {
      if (errorHandler) throw errorHandler(error);
      throw error;
    });
    this.logger.info('Response data', { endpoint, method, data: response.data });
    return response.data;
  }

  private createHeaders(apiKey: string) {
    const headers = { [RequestHeaderTypes.ContentType]: 'application/json' };
    if (apiKey) {
      headers[RequestHeaderTypes.ApiKey] = apiKey;
    }
    return headers;
  }

  private getApiKey(): string {
    const apiKey = this.localStorage.get(StorageKeys.paymashPayKey);
    if (!apiKey) throw new Error('Please provide Adyen api key in the settings');
    return apiKey;
  }

  private getNewEndpoint(endpoint: string) {
    const testMercahntIdRegex = /^Paymash_\w+_TEST/g;
    const merchantId = this.localStorage.get(StorageKeys.paymashPayMerchantId);
    if (testMercahntIdRegex.test(merchantId)) {
      return endpoint.replace('-live.adyen.com', '-test.adyen.com');
    }
    return endpoint;
  }
}

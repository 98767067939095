import {
  DocumentQualifier,
  ErrorCondition,
  MessageCategory,
  MessageClass,
  MessageType,
  ProtocolVersion,
  RequestName,
  ResponseResult,
} from '../../costants';
import { Injectable } from '@angular/core';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { CodecUtils } from '@pos-common/services/utils/codec.utils';
import {
  MakePaymentRequestOptions,
  MakePaymentResponse,
  PaymentResponse,
  PrinterTextItem,
  PrintReceiptRequestOptions,
  PrintReceiptResponse,
  PrintResponse,
  RequestHeaderOptions,
  StartScannerRequestOptions,
  AdminRequestOptions,
  CommonRequestOptions,
  AdminResponse,
  CancelScanSessionRequestOptions,
  StartScannerResponse,
  BarcodeAdminResponseData,
  AllowedPaymentBrand,
  TransactionStatusRequestOptions,
  TransactionStatusResponse,
} from '../../adyen-types';
import { AdyenApiHelper } from './adyen-api-helper.service';
import { LogService } from '@pos-common/services/system/logger/log.service';

@Injectable()
export class AdyenPaymentApi {
  private readonly baseUrl = SERVER_CONFIG.ADYEN_TERMINAL_API_URL;
  private readonly logger = this.logService.createLogger('AdyenPaymentApi');

  constructor(private readonly apiHelper: AdyenApiHelper, private logService: LogService) {}

  private get endpoint() {
    return `${this.baseUrl}sync`;
  }

  async makePayment(options: MakePaymentRequestOptions): Promise<MakePaymentResponse> {
    this.logger.debug('Start to make payment');
    const requestData = this.createPaymentRequestData(options);
    const data = this.getRequestData(
      {
        messageClass: MessageClass.Service,
        messageCategory: MessageCategory.Payment,
        ...options,
      },
      RequestName.PaymentRequest,
      requestData
    );
    const response = await this.apiHelper.post<PaymentResponse>(this.endpoint, data);
    this.logger.debug('Make payment response', { response });
    const paymentResponse = this.processPaymentResponse(response);
    this.logger.info('Payment made successfully', { paymentResponse });
    return paymentResponse;
  }

  async checkTransactionStatus(options: TransactionStatusRequestOptions): Promise<MakePaymentResponse> {
    this.logger.debug('Start to check transaction status', { options });
    const requestData = this.createTransactionStatusRequestData(options);
    const data = this.getRequestData(
      {
        messageClass: MessageClass.Service,
        messageCategory: MessageCategory.TransactionStatus,
        ...options,
      },
      RequestName.TransactionStatusRequest,
      requestData
    );
    const response = await this.apiHelper.post<TransactionStatusResponse>(this.endpoint, data);
    this.logger.debug('Transaction status response', { response });
    const paymentResponse = this.processTransactionStatusResponse(response);
    this.logger.info('Transaction status check was successful', { options, paymentResponse });
    return paymentResponse;
  }

  async printReceipt(options: PrintReceiptRequestOptions): Promise<void> {
    this.logger.debug('Start to print request');
    const requestData = this.createPrintRequestData(options);
    const data = this.getRequestData(
      {
        messageClass: MessageClass.Device,
        messageCategory: MessageCategory.Print,
        ...options,
      },
      RequestName.PrintRequest,
      requestData
    );
    const response = await this.apiHelper.post<PrintResponse>(this.endpoint, data);
    this.logger.debug('Printing response', { response });
    this.processPrintResponse(response);
    this.logger.info('Printing completed successfully');
  }

  async startScanner(options: StartScannerRequestOptions): Promise<StartScannerResponse> {
    this.logger.debug('Start barcode scanner');
    const data = {
      Session: {
        Id: options.sessionId,
        Type: 'Once',
      },
      Operation: [
        {
          Type: 'ScanBarcode',
          TimeoutMs: options.timeoutMs,
        },
      ],
    };
    const result = await this.sendAdminRequest<BarcodeAdminResponseData>({
      ...this.extractCommonOptions(options),
      data,
    });
    const scannerResponse: StartScannerResponse = {
      symbology: result.Barcode.Symbology,
      data: result.Barcode.Data,
    };
    this.logger.info('Scanning started successfully', { scannerResponse });
    return scannerResponse;
  }

  async cancelScanSession(options: CancelScanSessionRequestOptions): Promise<void> {
    this.logger.debug('Start cancelling scan session');
    const data = {
      Session: {
        Id: options.sessionId,
        Type: 'End',
      },
    };
    await this.sendAdminRequest({
      ...this.extractCommonOptions(options),
      data,
    });
    this.logger.info('Scanning session successfully cancelled');
  }

  private async sendAdminRequest<T = unknown>(options: AdminRequestOptions): Promise<T> {
    const requestData = this.createAdminRequestData(options);
    const data = this.getRequestData(
      {
        messageClass: MessageClass.Service,
        messageCategory: MessageCategory.Admin,
        ...options,
      },
      RequestName.AdminRequest,
      requestData
    );
    const response = await this.apiHelper.post(this.endpoint, data);
    return this.processAdminResponse<T>(response);
  }

  private extractCommonOptions<TOptions extends CommonRequestOptions>(options: TOptions): CommonRequestOptions {
    return {
      saleID: options.saleID,
      serviceID: options.serviceID,
      poiID: options.poiID,
    };
  }

  private getRequestData(headerOptions: RequestHeaderOptions, requestName: string, requestData: object) {
    return {
      SaleToPOIRequest: {
        MessageHeader: this.createMessageHeader(headerOptions),
        [requestName]: requestData,
      },
    };
  }

  private createMessageHeader({ saleID, serviceID, poiID, messageClass, messageCategory }: RequestHeaderOptions) {
    return {
      ProtocolVersion: ProtocolVersion.v3_0,
      MessageClass: messageClass,
      MessageCategory: messageCategory,
      MessageType: MessageType.Request,
      SaleID: saleID,
      ServiceID: serviceID,
      POIID: `${poiID.deviceModel}-${poiID.serialNumber.replace(/-/g, '')}`,
    };
  }

  private createPaymentRequestData({ saleTransaction, currency, amount, type, allowedPaymentBrand }: MakePaymentRequestOptions) {
    const saleData = {
      SaleTransactionID: {
        TransactionID: saleTransaction.transactionID,
        TimeStamp: saleTransaction.timeStamp,
      },
    };
    let transactionConditions: undefined | { AllowedPaymentBrand: AllowedPaymentBrand[] } = undefined;
    if (allowedPaymentBrand) {
      transactionConditions = {
        AllowedPaymentBrand: [allowedPaymentBrand],
      };
    }
    const paymentTransaction = {
      AmountsReq: {
        Currency: currency,
        RequestedAmount: amount,
      },
      TransactionConditions: transactionConditions,
    };
    if (type === 'payment') {
      return {
        SaleData: saleData,
        PaymentTransaction: paymentTransaction,
      };
    } else if (type === 'refund') {
      const paymentData = {
        PaymentType: 'Refund',
      };
      return {
        SaleData: saleData,
        PaymentTransaction: paymentTransaction,
        PaymentData: paymentData,
      };
    }
  }

  private createTransactionStatusRequestData(options: TransactionStatusRequestOptions) {
    return {
      ReceiptReprintFlag: true,
      DocumentQualifier: [DocumentQualifier.CashierReceipt, DocumentQualifier.CustomerReceipt],
      MessageReference: {
        SaleID: options.transactionSaleID,
        ServiceID: options.transactionServiceID,
      },
    };
  }

  private createPrintRequestData(options: PrintReceiptRequestOptions) {
    let outputContent;
    if (options.type === 'text') {
      outputContent = {
        OutputFormat: 'Text',
        OutputText: options.textItems,
      };
    } else if (options.type === 'qrCode') {
      outputContent = {
        OutputFormat: 'BarCode',
        OutputBarcode: {
          BarcodeType: 'QRCode',
          BarcodeValue: options.qrCode,
        },
      };
    } else if (options.type === 'image') {
      const xhtml = `<?xml version="1.0" encoding="UTF-8"?>
<img src="data:image/png;base64, ${options.imageBase64}"/>`;
      const xhtmlBase64 = btoa(xhtml);
      outputContent = {
        OutputFormat: 'XHTML',
        OutputXHTML: xhtmlBase64,
      };
    }
    return {
      PrintOutput: {
        DocumentQualifier: 'Document',
        ResponseMode: 'PrintEnd',
        OutputContent: outputContent,
      },
    };
  }

  private createAdminRequestData(options: AdminRequestOptions) {
    const jsonData = JSON.stringify(options.data);
    const base64Data = btoa(jsonData);
    return {
      ServiceIdentification: base64Data,
    };
  }

  private processCommonResponse(response: PaymentResponse) {
    if (response?.SaleToPOIRequest) {
      const { EventNotification } = response.SaleToPOIRequest;
      if (EventNotification) {
        const { EventToNotify } = EventNotification;
        if (EventToNotify && EventToNotify === 'Reject') {
          const { EventDetails } = EventNotification;
          const eventDetailsParams = CodecUtils.decodeSearchParamsToObject(EventDetails);
          const messageParam: string = eventDetailsParams['message'];
          throw new Error(`Payment error: ${messageParam}`);
        }
      }
      throw new Error(`Unknown error, EventNotification: ${EventNotification}`);
    }
    if (!response.SaleToPOIResponse) throw new Error('Cannot find response data');
    const { SaleToPOIResponse } = response;
    return { SaleToPOIResponse };
  }

  private processPaymentResponse(response: PaymentResponse): MakePaymentResponse {
    const { SaleToPOIResponse } = this.processCommonResponse(response);
    const { PaymentResponse } = SaleToPOIResponse;
    if (!PaymentResponse) throw new Error('Cannot find PaymentResponse');
    const { Response } = PaymentResponse;
    const additionalResponse = Response.AdditionalResponse;
    const additionalResponseObject = additionalResponse ? CodecUtils.decodeSearchParamsToObject(additionalResponse) : undefined;
    if (Response.Result === 'Failure') {
      const errorCondition = Response.ErrorCondition;
      if (additionalResponseObject) {
        if (errorCondition === ErrorCondition.Refusal) {
          const refusalReason = additionalResponseObject.refusalReason;
          if (refusalReason) {
            throw new Error(`Payment error: ${errorCondition}, Reason: ${refusalReason}`);
          }
        }
        const message = additionalResponseObject.message;
        if (message) {
          throw Error(message);
        }
      }
      throw Error(`Payment error: ${errorCondition}`);
    }
    if (Response.Result !== 'Success') {
      throw Error(`Payment error: ${Response.Result}`);
    }
    const { PaymentResult, PaymentReceipt } = PaymentResponse;
    const cashierReceipt = PaymentReceipt.find((receipt) => receipt.DocumentQualifier === 'CashierReceipt');
    const customerReceipt = PaymentReceipt.find((receipt) => receipt.DocumentQualifier === 'CustomerReceipt');
    return {
      serviceID: SaleToPOIResponse.MessageHeader.ServiceID,
      timeStamp: PaymentResponse.POIData.POITransactionID.TimeStamp,
      terminalID: SaleToPOIResponse.MessageHeader.POIID,
      cardName: this.getCardName({
        paymentBrand: PaymentResult.PaymentInstrumentData.CardData.PaymentBrand,
        paymentMethod: additionalResponseObject?.paymentMethod,
        paymentMethodVariant: additionalResponseObject?.paymentMethodVariant,
      }),
      cardholderReceipt: this.parseReceipt(customerReceipt.OutputContent.OutputText),
      merchantReceipt: this.parseReceipt(cashierReceipt.OutputContent.OutputText),
    };
  }

  private processTransactionStatusResponse(response: TransactionStatusResponse): MakePaymentResponse {
    const {
      SaleToPOIResponse: { TransactionStatusResponse, MessageHeader },
    } = this.processCommonResponse(response);
    if (!TransactionStatusResponse) throw new Error('Cannot find TransactionStatusResponse');
    const { Response } = TransactionStatusResponse;
    if (Response.Result === ResponseResult.Failure) throw Error(`TransactionStatus error: ${Response.ErrorCondition}`);
    const paymentResponse: PaymentResponse = {
      SaleToPOIResponse: {
        MessageHeader: MessageHeader,
        PaymentResponse: TransactionStatusResponse.RepeatedMessageResponse.RepeatedResponseMessageBody.PaymentResponse,
      },
    };
    return this.processPaymentResponse(paymentResponse);
  }

  private processPrintResponse(response: PrintResponse): PrintReceiptResponse {
    const {
      SaleToPOIResponse: { PrintResponse },
    } = this.processCommonResponse(response);
    if (!PrintResponse) throw new Error('Cannot find PrintResponse');
    const { Response } = PrintResponse;
    if (Response.Result !== 'Success') throw new Error('Print failure');
  }

  private processAdminResponse<T>(response: AdminResponse): T {
    const {
      SaleToPOIResponse: { AdminResponse },
    } = this.processCommonResponse(response);
    if (!AdminResponse) throw new Error('Cannot find AdminResponse');
    const {
      Response: { Result, AdditionalResponse },
    } = AdminResponse;
    if (Result !== 'Success') throw new Error('Admin request failure');
    const jsonResultData = atob(AdditionalResponse);
    const resultData = JSON.parse(jsonResultData);
    if (typeof resultData !== 'object') throw new Error('Admin request failure: bad response type');
    return resultData;
  }

  private getCardName(options: {
    paymentBrand: string;
    paymentMethod: string | undefined;
    paymentMethodVariant: string | undefined;
  }): string {
    this.logger.debug('Get card name', {
      paymentBrand: options.paymentBrand,
      paymentMethod: options.paymentMethod,
      paymentMethodVariant: options.paymentMethodVariant,
    });

    const paymentMethod = options.paymentMethod ?? options.paymentBrand;
    const paymentMethodVariant = options.paymentMethodVariant;

    let detectedCardName: string;

    if (paymentMethodVariant === 'maestro') {
      detectedCardName = 'Maestro';
    } else if (paymentMethod.startsWith('mc')) {
      detectedCardName = 'Mastercard';
    } else if (paymentMethod.startsWith('visa')) {
      detectedCardName = 'Visa';
    } else {
      detectedCardName = paymentMethod;
    }

    this.logger.info(`Detected card name: ${detectedCardName}`);
    return detectedCardName;
  }

  private parseReceipt(receipts: PrinterTextItem[]): string {
    return receipts.reduce((builder, receipt) => {
      const { Text, EndOfLineFlag } = receipt;
      const { name, value, key } = CodecUtils.decodeSearchParamsToObject(Text);
      if (key.indexOf('header') > -1) {
        return builder;
      }
      let row = '';
      if (key === 'filler') {
        row = '-';
      } else {
        row = !value ? name : `${name}   ${value}`;
      }
      row += EndOfLineFlag ? '\n' : row;
      return builder + row;
    }, '');
  }
}

import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { Injectable } from '@angular/core';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import {
  MakePaymentOptions,
  PaymentType,
  MakePaymentRequestOptions,
  CommonRequestOptions,
  TransactionStatusRequestOptions,
} from './adyen-types';
import { AdyenPaymentApi } from './services/api/adyen-payment-api.service';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import { LogService } from '../logger/log.service';
import { AdyenPaymentErrors, ErrorCondition } from './costants';
import { TranslateService } from '@ngx-translate/core';
import { ErrorTranslationHelper } from '@pos-common/classes';
import { DefaultPaymentMethods } from '@pos-common/constants';
import { concatMap, defer, delay, from, iif, of, retryWhen, throwError } from 'rxjs';

type MakeTransactionOptions = { type: PaymentType } & MakePaymentOptions;

@Injectable()
export class AdyenPaymentService {
  private readonly errorTranslationHelper = new ErrorTranslationHelper();
  private readonly logger = this.logService.createLogger('AdyenPaymentService');

  constructor(
    private readonly adyenPaymentApi: AdyenPaymentApi,
    private readonly paymentProcessingService: PaymentProcessingService,
    private readonly utils: AdyenUtils,
    private readonly logService: LogService,
    private readonly translateService: TranslateService
  ) {
    this.setupErrorMessages();
  }

  private setupErrorMessages() {
    this.errorTranslationHelper.setupMessages([
      [AdyenPaymentErrors.userCancel, 'terminal_transaction_error_user_cancel'],
      [AdyenPaymentErrors.provideTerminalInfo, 'settings_provide_terminal_info'],
      [AdyenPaymentErrors.currencyNotSupported, 'terminal_transaction_error_wrong_currency'],
      [AdyenPaymentErrors.refundNotEnabled, 'payment_method_does_not_support_refund'],
      [AdyenPaymentErrors.notEnoughBalance, 'terminal_transaction_error_not_enough_balance'],
      [AdyenPaymentErrors.twintNotEnabled, 'terminal_transaction_error_twint_disabled'],
      [AdyenPaymentErrors.declinedOnline, 'terminal_transaction_error_declined'],
      [AdyenPaymentErrors.notPresentCard, 'terminal_transaction_error_not_present_card'],
    ]);
  }

  makePayment(options: MakePaymentOptions): Promise<PaymentResult> {
    return this.makeTransaction({ type: 'payment', ...options });
  }

  makeRefund(options: MakePaymentOptions): Promise<PaymentResult> {
    return this.makeTransaction({ type: 'refund', ...options });
  }

  private async makeTransaction(options: MakeTransactionOptions) {
    try {
      this.paymentProcessingService.init();
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.processing);
      const requestOptions = this.getPaymentRequestOptions(options);
      const makePaymentResponse = await this.adyenPaymentApi.makePayment(requestOptions);
      const paymentResult = new PaymentResult(PAYMENT_PROVIDERS.PAYMASH_PAY);
      paymentResult.setPaymentResultData(makePaymentResponse);
      return paymentResult;
    } catch (error) {
      this.logger.error(error, 'makeTransaction:makePayment');
      const message = this.errorTranslationHelper.getTranslationKey(error?.message) || 'connection_timeout';
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
        message: this.translateService.instant(message),
        retryButtonOff: true,
      });
      throw error;
    }
  }

  private getPaymentRequestOptions(options: MakeTransactionOptions): MakePaymentRequestOptions {
    const { currency, amount, paymentUuid, type, paymentMethod } = options;
    const requestOptions: MakePaymentRequestOptions = {
      ...this.utils.createCommonRequestOptions(),
      currency,
      amount,
      saleTransaction: {
        transactionID: paymentUuid, // "27908" -- your reference to identify a payment. We recommend using a unique value per payment. In your Customer Area and Adyen reports, this will show as the merchant reference for the transaction.
        timeStamp: new Date().toISOString(), // "2019-03-07T10:11:04+00:00" -- date and time of the request in UTC format.
      },
      type,
      allowedPaymentBrand: paymentMethod === DefaultPaymentMethods.TWINT ? 'twint_pos' : undefined,
    };
    return requestOptions;
  }

  private checkTransactionStatus(options: CommonRequestOptions) {
    return defer(() => {
      const requestOptions = this.getTransactionStatusRequestOptions(options);
      return from(this.adyenPaymentApi.checkTransactionStatus(requestOptions));
    })
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) => iif(() => e?.message?.includes(ErrorCondition.InProgress) && i < 4, of(e).pipe(delay(1000)), throwError(e)))
          )
        )
      )
      .toPromise();
  }

  private getTransactionStatusRequestOptions(options: CommonRequestOptions): TransactionStatusRequestOptions {
    return {
      ...this.utils.createCommonRequestOptions(),
      transactionSaleID: options.saleID,
      transactionServiceID: options.serviceID,
    };
  }
}
